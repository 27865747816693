import dataProvider from "./data/dataProvider";
import {
  getRolePowers,
  getUserID,
  onUserLogout,
  setTokenUuid,
  getUserChecks,
  getPermissions,
  getSubscriptionPermission,
  getMvpleadData,
} from "./utils/AuthUtil";
import { is_empty } from "./utils/validations";
import { getParameterByName } from "./utils/Utils";
import { api } from "data";
import posthog from "posthog-js";
import { logError } from "utils/error";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "utils/getterAndSetters";
import { AUTH_STORAGE_CONSTANTS } from "authConstants";
import { fetchCreatorConfig } from "features/Common/modules/CreatorData/utils/CreatorData.utils";
import { JWT_GRANT_TYPES } from "data/api.constants";

export const identifyUser = async (userId) => {
  try {
    const isStaff =
      getLocalStorageItem(AUTH_STORAGE_CONSTANTS.IS_STAFF) ||
      getLocalStorageItem(AUTH_STORAGE_CONSTANTS.BD_LOGIN)
        ? true
        : false;
    const staffEmail = getLocalStorageItem(AUTH_STORAGE_CONSTANTS.BD_EMAIL);

    let mvpLeadData = await getMvpleadData();
    let payload = {
      user_id: userId,
      is_staff: isStaff,
      staff_email: staffEmail,
    };

    if (!is_empty(mvpLeadData)) {
      delete mvpLeadData.creator__phone_number; // do not want creator phone number to be sent to posthog
      Object.assign(payload, mvpLeadData);
    }

    const userIdentity =
      getLocalStorageItem(AUTH_STORAGE_CONSTANTS.USER_IDENTITY) || {};
    if (!userIdentity.isIdentified || isStaff !== userIdentity.isStaff) {
      posthog.identify(userId, payload);
    }
    const user_identity = {
      isStaff,
      isIdentified: true,
    };
    setLocalStorageItem(AUTH_STORAGE_CONSTANTS.USER_IDENTITY, user_identity);
  } catch (error) {
    logError({
      error,
      occuredAt: "identifyUser in src/authProvider.js",
      when: "calling getMvpLeadData",
    });
  }
};

export const onUserLogin = (
  username,
  grantType = JWT_GRANT_TYPES.AUTHORIZATION_CODE
) => {
  return new Promise((resolve, reject) => {
    setTokenUuid(username);
    dataProvider.fetchJwt(grantType).then((uuid_status) => {
      if (uuid_status) {
        const loginDataPromises = [
          getUserChecks(),
          getPermissions(),
          getRolePowers(),
          getSubscriptionPermission(),
          fetchCreatorConfig(),
        ];
        Promise.all(loginDataPromises)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            logError({
              error,
              occuredAt: "src/authProvider.js",
              when: "onUserLogin̉",
            });
          });
      } else {
        reject(new Error("uuid_status not found"));
      }
    });
  });
};

const authProvider = {
  login: ({ grantType, loginPayload }) => {
    const payload = loginPayload;
    const ref =
      getParameterByName("ref") || window.sessionStorage.getItem("ref");
    if (!is_empty(ref)) {
      payload.ref = ref;
    }
    switch (grantType) {
      case "google":
        return dataProvider
          .custom_request(api.post_google_login, "POST", payload)
          .then(async (data) => {
            if (
              data &&
              data.data &&
              data.data.status &&
              data.data.status !== "success"
            ) {
              return { status: data.data.status };
            }
            await onUserLogin(data?.data?.user_ref?.username);
            return {
              newUser: data.data.new_user_flag,
              status: data.data.status,
            };
          })
          .catch((error) => {
            return {
              error: error?.body?.data?.message,
              status: error?.body?.status,
              signup_status: error?.body?.data?.status,
            };
          });
      case "emailNew":
        return dataProvider
          .custom_request(api.create_new_user, "POST", payload)
          .then(async (data) => {
            if (
              data &&
              data.data &&
              data.data.status &&
              data.data.status === "failure"
            ) {
              return { status: data.data.status, error: data.data.error };
            }
            await onUserLogin(data?.data?.userId);
            return {
              newUser: 1,
              error: data.data.error,
              status: data.data.status,
            };
          })
          .catch((error) => {
            return {
              error: error?.body?.message || error?.body?.data?.error,
              status: error?.body?.status,
            };
          });
      default:
        return dataProvider
          .custom_request(api.call_existing_user, "POST", loginPayload)
          .then(async (data) => {
            if (
              data &&
              data.data &&
              data.data.status &&
              data.data.status === "failure"
            ) {
              return { status: data.data.status, error: data.data.error };
            }

            await onUserLogin(data?.data?.userId);
            return {
              newUser: 0,
              error: data.data.error,
              status: data.data.status,
            };
          })
          .catch((error) => {
            return {
              error: error?.body?.data?.error || error?.body?.message,
              status: error?.body?.status,
            };
          });
    }
  },
  logout: async () => {
    await dataProvider.custom_request(
      `notifications/exly/webpush/logout`,
      "POST"
    );
    onUserLogout(false);
    return Promise.resolve();
  },
  checkError: (data) => {
    const status = data.status;
    const code = data.body.status;

    if (
      status === 401 ||
      status === 403 ||
      (code && status === 400 && (code === 6 || code === 3)) // TODO: what does values of `code` represent?
    ) {
      onUserLogout(true);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return !is_empty(getUserID()) ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => Promise.reject("Unknown method"),
  getIdentity: () => {
    try {
      const { first_name, display_image } = JSON.parse(
        getLocalStorageItem("auth")
      );
      return Promise.resolve({
        id: getLocalStorageItem(AUTH_STORAGE_CONSTANTS.UUID),
        fullName:
          window.screen.width < 780
            ? ""
            : `${first_name.substring(0, 9)}${
                first_name.length > 9 ? "..." : ""
              }`,
        avatar: display_image,
      });
    } catch (error) {
      return Promise.resolve({
        id: getLocalStorageItem(AUTH_STORAGE_CONSTANTS.UUID),
        fullName: "",
        avatar: null,
      });
    }
  },
};

export default authProvider;
